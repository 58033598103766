import {Link} from 'react-router-dom';
import CurrencyIcon from '..//common/icon';
import {showDateTime, showPrice, showStrikePrice, showPercent} from '../../libs/util';

const Component = ({transaction, referenceId}) => {
  const isPut = transaction?.product?.option &&  transaction?.product?.option?.option_type == 'PUT';
  return (
    <>
    <div className="dark-text">TXN ID #{referenceId}</div>
    <div className="dark-text">Purchase investment {transaction.product?.description}</div>
    <div className="nk-odr-item p-0 pt-1 pb-2">
      <div className="nk-odr-col">
        <div className="nk-odr-info">
          <div className="nk-odr-badge">
            {/*{isPut ? */}
            {/*  (<CurrencyIcon currency={transaction?.product?.option?.quote_currency}/>) : (<CurrencyIcon currency='USDC'/>)*/}
            {/*}*/}

            <CurrencyIcon currency={'in'}/>
          </div>
          <div className="nk-odr-data">
            <div className="nk-odr-label text-danger">-{showPrice(transaction.invest_amount, transaction.invest_ccy)}</div>
            <div className="nk-odr-meta">{showDateTime(transaction.value_datetime)}</div>
          </div>
        </div>
      </div>
      <div className="nk-odr-col nk-odr-col-amount text-right">{transaction.order_status}</div>
    </div>

    <div className="mt-1 dark-text">INVESTMENT DETAILS</div>
    <div className="row mt-1">
      <div className="col-6">
        <span className="lead-text">Purchase Amount</span>
        <span className="sub-text">{showPrice(transaction.invest_amount, transaction.invest_ccy)}</span>
      </div>
      <div className="col-6">
        <span className="lead-text">Debited from Wallet</span>
        <span className="sub-text">-{showPrice(transaction.invest_amount, transaction.invest_ccy)}</span>
      </div>
    </div>
    <div className="row mt-1">
      <div className="col-6">
        <span className="lead-text">Detail</span>
        <span className="sub-text">{transaction.product?.description} {transaction?.product?.option?.quote_currency ??  transaction.invest_ccy}/USD</span>
      </div>
      <div className="col-6">
        <span className="lead-text">Debit Reference</span>&nbsp;
      </div>
    </div>

    <div className="mt-2 dark-text border-top pt-1">ADDITIONAL</div>
    <div className="row mt-1">
      <div className="col-6">
        <span className="lead-text">Product</span><span className="sub-text">{transaction.product?.description}</span></div>
      <div className="col-6"><span className="lead-text">Maturity Date</span><span className="sub-text">{showDateTime(transaction.maturity_datetime)}</span></div>
    </div>
    {transaction.product?.option?.strike_price && <div className="row mt-1">
      <div className="col-6"><span className="lead-text">Strike Rate</span><span className="sub-text">{showStrikePrice(transaction.product?.option?.strike_price, 'USD')} </span></div>
      <div className="col-6"><span className="lead-text">Annualised Return</span><span className="sub-text">{showPercent(transaction.term_apy)}</span></div>
    </div>}
    <div className="row mt-1"> 
      <div className="col-6">
        <span className="lead-text">Expected Return {isPut ? `(${String.fromCharCode(8805)} : ${String.fromCharCode(8804)} Strike Rate)` : ""}</span>
        <span className="sub-text">{isPut ? showPrice(transaction.term_exercised_amount, transaction.term_exercised_amount_ccy) : showPrice(transaction.term_expired_amount, transaction.term_expired_amount_ccy)}</span>
      </div>
     {transaction.term_exercised_amount_ccy &&  <div className="col-6">
        <span className="lead-text">Expected Return ({isPut ? String.fromCharCode(60) : String.fromCharCode(62)} Strike Rate)</span>
        <span className="sub-text">{isPut ? showPrice(transaction.term_expired_amount, transaction.term_expired_amount_ccy) : showPrice(transaction.term_exercised_amount, transaction.term_exercised_amount_ccy)}</span>
      </div>}
    </div>

    <div className="mt-2 dark-text border-top pt-1">TIMELINE</div>
    <div className="row mt-1">
      <div className="col-6"><span className="lead-text">Requested On</span><span className="sub-text">{showDateTime(transaction.value_datetime)}</span></div>
      <div className="col-6"><span className="lead-text">Mature On</span><span className="sub-text">{showDateTime(transaction.maturity_datetime)}</span></div>
    </div>

    </>
  )
}

export default Component;
